import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
    TableOptions, TablesNameEnum,
} from 'src/app/shared/model/app-table.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FilingService } from '../../filing.service';
import { FiingPeriodList, PermitTypeEnum } from '../../models/filing.model';
import { ActionResponse, GetDataOptions } from 'src/app/shared/model/shared.model';

@Component({
    selector: 'app-table-popup',
    templateUrl: './ifta-table-popup.component.html',
    styleUrls: ['./ifta-table-popup.component.scss'],
})
export class IftaTablePopupComponent implements OnInit {
    dataTableConfig: TableOptions<FiingPeriodList> | null = null;
    tableData: FiingPeriodList[] = [];
    permitType: string | PermitTypeEnum = PermitTypeEnum.IFTA;
    year: number = 0;
    quarter: number = 0;
    constructor(
        private sharedService: SharedService,
        private filingService: FilingService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.permitType = this.route.snapshot.queryParams.permitType;
        this.year = this.route.snapshot.params.year;
        this.quarter = this.route.snapshot.params.quarter;

        if (
            this.permitType == PermitTypeEnum.IFTA ||
            this.permitType == 'IFTA'
        ) {
            this.dataTableConfig = {
                name: TablesNameEnum.IFTA,
                allowSearch: false,
                allowOrdering: false,
                allowSettings: false,
                column: [
                    {
                        label: 'STATE',
                        key: 'state',
                        visible: true,
                        width: '7rem'
                    },

                    {
                        label: 'MILEAGE',
                        key: 'distance',
                        width: '7rem',
                        visible: true,
                    },
                    {
                        label: 'GALLONS',
                        key: 'fuelQuantity',
                        width: '7rem',
                        visible: true,
                    },
                    {
                        label: 'REEFER_GALLONS',
                        key: 'reeferQuantity',
                        width: '7rem',
                        visible: true,
                    },
                ],
                allowActions: false,
                allowTableHeader: false,
            tableHeight: "100%",
                getData: (searchObj: GetDataOptions) => this.getTableData(searchObj),
                allowCards: false,
            };
        } else {
            this.dataTableConfig = {
                name: TablesNameEnum.IFTA,
                allowSearch: false,
                allowOrdering: false,
                allowTableHeader: false,
                allowSettings: false,
                column: [
                    {
                        label: 'TRUCK_ID',
                        key: 'assetCode',
                        visible: true,
                    },

                    {
                        label: 'STICKER_NUMBER',
                        key: 'permitTruckLicenseNumber',
                        visible: true,
                    },
                    {
                        label: 'TOTAL_MILEAGE',
                        key: 'distance',
                        visible: true,
                    },
                ],
                allowActions: false,
                getData: (searchObj: GetDataOptions) => this.getTableData(searchObj),
                allowCards: false
            };
        }
    }

    async getTableData(
        options: GetDataOptions
    ): Promise<ActionResponse<FiingPeriodList>> {
        const response = await this.filingService.getPermitDetails(
            this.year,
            this.quarter,
            this.permitType
        );
        if (response.success) {
            this.tableData = response.data?.permitFilingDetails ?? [];

            if (this.tableData.length > 0) {
                this.tableData.forEach((el) => {
                    el.distance = this.sharedService.numberWithCommas(
                        el.distance
                    );
                    el.fuelAmount = this.sharedService.numberWithCommas(
                        el.fuelAmount
                    );
                    el.fuelQuantity = this.sharedService.numberWithCommas(
                        el.fuelQuantity
                    );
                    el.reeferAmount = this.sharedService.numberWithCommas(
                        el.reeferAmount
                    );
                    el.reeferQuantity = this.sharedService.numberWithCommas(
                        el.reeferQuantity
                    );
                });
            }
        }
        return {
            success: true,
            data: this.tableData,
            errMsg: '',
            isRefresh: true,
        };
    }
}
