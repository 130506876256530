<!-- <div class="container-fluid">
    <div class="row">
        <div class="col-12" *ngIf="dataTableConfig">
            <tc-data-table [tableOptions]="dataTableConfig"></tc-data-table>
        </div>
    </div>
</div> -->

<div class="tc-table-container-tabs">
    <div *ngIf="dataTableConfig" class="tc-data-table" id="tc-data-table">
        <tc-data-table [tableOptions]="dataTableConfig"></tc-data-table>
    </div>
</div>